import React, {useEffect, useRef, useState} from "react";
import "./App.css";
import {Button, Layout} from "antd";
import { database } from "./firebase";
import "firebase/database";

import SignatureCanvas from "react-signature-canvas";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";


const Estimate = () => {
    const { id } = useParams();
	const [state, setState] = useState({
		pdfData: null,
		generatedPdf: "",
		signature: "",
		loading: false,
		btndisable:true
	});

	const urlParams = new URLSearchParams(window.location.search);
	const reloaded = urlParams.get("reloaded");


	const SignatureRef = useRef();
	const path = window.location.pathname;
	useEffect(() => {
		if(id === "/" || id === undefined){
			window.location.replace("https://valleyasphalt.us/");
		}else{
			SignatureRef.current.off()
			onComponentMountAsync(id);
		}
	}, [id]);
	const onComponentMountAsync = async (id) => {
		const dbRef = database.ref();
		let btnstate = true
		dbRef.child("estimates/"+id).once('value').then((snapshot) => {
            console.log(snapshot.val().SalesOrderimageURL)
            console.log(snapshot.val().SalesOrderimageURL === undefined || snapshot.val().SalesOrderimageURL === "")
		  if (snapshot.exists()) {
			if(snapshot.val().SalesOrderSignature === undefined || snapshot.val().SalesOrderSignature === ""){
                if(snapshot.val().SalesOrderimageURL === undefined || snapshot.val().SalesOrderimageURL === "" ){
                    Swal.fire({
                        title: "Error!",
                        text: "Your sales order has not yet been processed, we apologize for the inconvenience.",
                        icon: "error"
                      });
                }else{
                    SignatureRef.current.on()
                    btnstate = false
                }
				
			}else{
				console.log("el estimado esta firmado")
				Swal.fire({
					title: reloaded? "success!" : "ATTENTION!",
					text: reloaded? "Sales Order signed successfully!" : "The Sales Order Is Signed",
					icon: reloaded? "success" : "warning"
				  });
			}
			setState({...state, pdfData:snapshot.val(), btndisable:btnstate})
		  } else {
			console.log("No data available");
		  }
		}).catch((error) => {
		  console.error(error);
		});
	};

	const uploadPdf = async () => {
		//console.log(SignatureRef.current.getTrimmedCanvas().toDataURL("image/png"))
		setState({...state, loading: true});
		if (SignatureRef.current.isEmpty()) {
			setState({...state, loading: false});
			return console.log("empty");
		}
		await database.ref("estimates/" + id).update({
			SalesOrderSignature: SignatureRef.current.getTrimmedCanvas().toDataURL("image/png"),
		}).then(async ()=>{
			const response = await fetch("https://us-central1-valley-asphalt.cloudfunctions.net/generateSalesOrder?type=emailsms&estimateID=" + id + "&user=alberto@studiomediaagency.com", {
				method: "GET",
			})
			if (response.ok) {
				const result = await response.json();
				setState({...state, loading: false});
				console.log('Function executed successfully:', result);
				window.location.replace(`${window.location.href}?reloaded=true`);

			} else {
				setState({...state, loading: false});
				console.error('Error executing function:', response.statusText);
				Swal.fire({
					title: "Error!",
					text: "The Sales Order Could Not Be Signed",
					icon: "error"
				  });
				window.location.replace(`${window.location.href}`);
			}
		})

		//setState({...state, loading: false});
		//notification.success({ message: "Success!", description: "Estimate signed successfully!" })
		/*Swal.fire({
			title: "Success!",
			text: "Estimate signed successfully!",
			icon: "success"
		  });
		window.location.replace("https://valleyasphalt.us/");*/
	};

	return (
	    <Layout style={{ minHeight: '100vh', backgroundColor:"#ededed", width: '100%' }}>
        <div style={{ marginBottom: '20px', width: "100%" }}>
          <img
            alt="Estimate"
            src={`${state.pdfData?.SalesOrderimageURL}`}
            style={{ 
				width: '100%', 
				height: state.pdfData?.SalesOrderSignature === undefined || state.pdfData?.SalesOrderSignature === "" ? '65vh' : "100vh", 
				border: '1px solid black', 
				objectFit: 'contain' }}
          />
        </div>

        {
        state.pdfData?.SalesOrderSignature === undefined || state.pdfData?.SalesOrderSignature === "" ? 
		<>
			<div style={{ marginBottom: '10px', textAlign: 'center', fontSize: '24px', width: "100%" }}>
					<SignatureCanvas
						backgroundColor={"#ffff"}
						penColor={"#000"}
						
						ref={SignatureRef}
						canvasProps={{
							width: "300vw",
							height: 70,
							className: "sigCanvas",
						}}
					/>
			</div>

			<div style={{ marginBottom: '10px', textAlign: 'center', fontSize: '18px' }}>
			Sign here
			</div>

			<div style={{ textAlign: 'center' }}>
			<Button 
				disabled={state.btndisable}
				type="primary" size="large"
				loading={state.loading}
				onClick={() => uploadPdf()}
				>
					Submit Signature
			</Button>
			</div>
		</>
		:null
		}
    </Layout>
	);
}

export default Estimate;

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/database'; 

const firebaseConfig = {
  apiKey: "AIzaSyA5gY2jOVdedCeM7UdUs0UWuuAotiBFWNY",
  authDomain: "valley-asphalt.firebaseapp.com",
  databaseURL: "https://valley-asphalt-default-rtdb.firebaseio.com",
  projectId: "valley-asphalt",
  storageBucket: "valley-asphalt.appspot.com",
  messagingSenderId: "712732749553",
  appId: "1:712732749553:web:39bd86a9e68efbb34b0350"
};

// Inicializa Firebase
firebase.initializeApp(firebaseConfig);

// Obtiene la instancia de Firestore
const firestore = firebase.firestore();

// Obtiene la instancia de Storage
const storage = firebase.storage();

// Obtiene la instancia de Realtime Database
const database = firebase.database();

export { firestore, storage, database };

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Estimate from './Estimate';
import SalesOrder from './SalesOrder';
import Test from './test';

const App = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Estimate/>} />
	  <Route path="/:id" element={<Estimate />} />
      <Route path="/SalesOrder/" element={<SalesOrder/>} />
	  <Route path="/SalesOrder/:id" element={<SalesOrder />} />
    </Routes>
  );
};

export default App;

